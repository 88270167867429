<template>
    <header id="header" class="h-20 hidden md:block text-base shrink-0">
        <nav class="main-nav">
            <div class="nav-default">
                <a class="py-5 h-20 leading-10" :href="localePath('/')">
                    <EnhancedImage
                        css-class="brand-logo"
                        :src="logoUrl"
                        alt="TrustedParts.com"
                        :high-res="true"
                        height="40"
                        width="275" />
                </a>

                <div class="py-2 grow" role="search">
                    <HeaderSearchBox
                        :placeholder="$t('Layout.SearchPartNumberDefaultText')"
                        :language="locale"
                        :region="currentRegion.ShortName"
                        :is-home="false" />
                </div>

                <!-- Normal Filters -->
                <div class="hidden xl:flex justify-evenly items-center space-x-5">
                    <HeaderLanguageMenu />
                    <HeaderCurrenciesMenu />
                    <HeaderRegionsMenu />
                </div>

                <!-- Mini Filters -->
                <div class="block xl:hidden">
                    <HeaderFiltersMenu />
                </div>

                <HeaderAccountMenu />
            </div>
        </nav>
    </header>
</template>
<script setup>
const { locale } = useI18n();
const localePath = useLangPath();
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
const logoUrl = cdnUrl + "/assets/images/trusted-parts-logo-sm.png";

const currentRegion = useCurrentRegion();
</script>
