<template>
    <nav
        class="group md:hidden bg-accent mb-0 w-full px-4 relative shrink-0"
        :class="{ 'h-auto': props.isHome, 'h-[75px] inner': !props.isHome }">
        <div class="nav-mobile">
            <a class="shrink-0 mr-4" :href="localePath('/')">
                <EnhancedImage
                    css-class="hidden xs:block group-[.open]:hidden"
                    :src="logoUrl"
                    alt="TrustedParts.com"
                    :high-res="true"
                    height="35"
                    width="236" />

                <EnhancedImage
                    css-class="block xs:hidden group-[.open]:block"
                    :src="iconUrl"
                    alt="TrustedParts.com"
                    :high-res="true"
                    height="32"
                    width="32" />

                <template v-if="props.isHome">
                    <h1 class="search-tag-line">$t('Layout.SearchHeader')</h1>
                </template>
            </a>

            <HeaderMobileSearchBox
                :placeholder="$t('Layout.SearchPartNumberDefaultText')"
                :language="locale"
                :region="currentRegion.ShortName"
                :is-home="props.isHome" />

            <HeaderMobileMenu />
        </div>
    </nav>
</template>

<script setup>
const { locale } = useI18n();
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;

const props = defineProps({ isHome: { type: Boolean, default: false } });

const logoUrl = cdnUrl + "/assets/images/trusted-parts-logo.png";
const iconUrl = cdnUrl + "/favicon-32x32.png";

const currentRegion = useCurrentRegion();
</script>
